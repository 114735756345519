// import Vue from 'vue'
import consts from "@/consts"
import {diffObjects} from "@/lib/lib";
import range from "lodash/range";

export default {
    state: {
        videoStatusesFullLoad: false,
        video_statuses: [],
    },
    actions: {
        fetchVideoStatuses/*all*/({ dispatch, getters }, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchVideoStatuses', time: Date.now() / 1000})

                const params = args//getApiProps('video_statuses', args)
                this.$api.videoStatuses.getAll(params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            resolve(response.data)
                        } else {
                            reject(response.data)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchVideoStatuses', inprogress: false})
                    });
            })
        },
        fetchVideoStatusesAll({dispatch}) {
            return dispatch('fetchVideoStatusesAllPages')
        },
        fetchVideoStatusesAllPages({dispatch, commit, getters}) {
            return new Promise((resolve, reject) => {
                    if (!getters.apiToken || !getters.isUnitsFullLoad) {
                        return reject(null)
                    }
                    if (!getters.units.length) {
                        return resolve([])
                    }
                    dispatch('setLastCall', {name: 'fetchVideoStatus', time: Date.now() / 1000})
                    dispatch('setLastCall', {name: 'fetchVideoStatusesAll', time: Date.now() / 1000})
                    dispatch('setLastCall', {name: 'fetchVideoStatusesChanged', time: Date.now() / 1000})

                    //let fetch = Object.entries(getters.getUnitsIdsByCameraTypes).map(([hwType, units]) => {
                        //let count = units.length
                    let fetch = getters.getUnitsCameraTypes.map(hwType => {
                        let count = 1
                        let pageSize = consts.querySettings.pageSize
                        let pages = Math.ceil(count / pageSize)
                        return range(pages).map(i => {
                            let page = i + 1;
                            return dispatch('fetchVideoStatuses', {x: page, 'page-size': pageSize, hw_type: hwType})
                                .then((data) => {
                                    commit('updateVideoStatuses', data)
                                })
                                .catch(() => {
                                    dispatch('fetchVideoStatuses', {x: page, 'page-size': pageSize, hw_type: hwType})
                                })
                        })
                    }).flat();
                    resolve(fetch)
                })
                .then((fetch) => {
                    return Promise.all(fetch)
                        .then(() => {
                            commit('setVideoStatusesFullLoad', true)
                        })
                })
                .catch((error) => {
                    if (this.$isDevelopment) console.error(error);
                })
                .finally(() => {
                    dispatch('setLastCall', {name: 'fetchVideoStatus', inprogress: false})
                    dispatch('setLastCall', {name: 'fetchVideoStatusesAll', inprogress: false})
                    dispatch('setLastCall', {name: 'fetchVideoStatusesChanged', inprogress: false})
                });
        },
        fetchGroupVideoStatuses({ commit, dispatch, getters }, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken || !args.groupId) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchGroupVideoStatuses', time: Date.now() / 1000})

                const params = args.params //getApiProps('video_statuses', args)
                this.$api.videoStatuses.forGroup(args.groupId, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            resolve(response.data)
                        } else {
                            reject(response.data)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchGroupVideoStatuses', inprogress: false})
                    });
            })
            .then((data) => {
                commit('updateVideoStatuses', data)
            })
        },
        fetchVideoStatus({ commit, dispatch, getters }, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken || !args.unitId || !getters.isUnitsLiteLoad || !getters.isUnitsLmsgsLiteLoad) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchVideoStatus', time: Date.now() / 1000})

                const params = args.params //getApiProps('video_statuses', args)
                this.$api.videoStatuses.forUnit(args.unitId, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            resolve(response.data)
                        } else {
                            reject(response.data)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchVideoStatus', inprogress: false})
                    });
            })
                .then((data) => {
                    commit('updateVideoStatus', data)
                })
        },

    },
    mutations: {
        setVideoStatusesFullLoad(state, FullLoad) {
            state.videoStatusesFullLoad = state.videoStatusesFullLoad || FullLoad
        },
        updateVideoStatuses(state, nVideoStatuses) {
            if(!state.video_statuses.length) {
                state.video_statuses = nVideoStatuses.map(vs => Object.freeze(vs))
                return true
            }

            nVideoStatuses.forEach(function (nVideoStatus) {
                let i = state.video_statuses.findIndex(vs => (vs.unit_id == nVideoStatus.unit_id))
                if(i<0) {
                    state.video_statuses.push(Object.freeze(nVideoStatus))
                }else{
                    // updateObjectByDiff(state.video_statuses[i], nVideoStatus)
                    nVideoStatus = diffObjects(state.video_statuses[i], nVideoStatus)
                    if(nVideoStatus) state.video_statuses[i] = Object.freeze({...state.video_statuses[i], ...nVideoStatus})
                }
            })
        },
        updateVideoStatus(state, nVideoStatus) {
            let i = state.video_statuses.findIndex(vs => (vs.unit_id == nVideoStatus.unit_id))
            if(i<0) {
                state.video_statuses.push(Object.freeze(nVideoStatus))
            }else{
                // updateObjectByDiff(state.video_statuses[i], nVideoStatus)
                nVideoStatus = diffObjects(state.video_statuses[i], nVideoStatus)
                if(nVideoStatus) state.video_statuses[i] = Object.freeze({...state.video_statuses[i], ...nVideoStatus})
            }
        },
        deleteVideoStatus(state, unit_id) {
            let i = state.video_statuses.findIndex(vs => (vs.unit_id == unit_id))
            if(i != -1) {
                state.video_statuses.splice(i, 1)
            }
        },
    },
    getters: {
        isVideoStatusesFullLoad(state){
            return state.videoStatusesFullLoad
        },
        getVideoStatuses(state){
            return state.video_statuses
        },
        getVideoStatusesByUnitsIds(state){
            // return state.video_statusesObj
            return state.video_statuses.reduce((video_statusesById, video_status) => {
                video_statusesById[video_status.unit_id] = video_status
                return video_statusesById
            }, {})
        },
        getVideoStatusesByTypes(state){
            // return state.video_statusesObj
            return state.video_statuses.reduce((video_statusesById, video_status) => {
                video_statusesById[video_status.hw_type] = video_status
                return video_statusesById
            }, {})
        },
    }
}
