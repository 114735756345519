// import Vue from 'vue'
// import consts from "@/consts"
// import range from "lodash/range";
import {getApiProps, updateObjectByDiff} from "@/lib/lib";
import consts from "@/consts";
// import {arraySplitIntoChunks} from "@/lib/lib";

const with_removed = String(process.env.VUE_APP_PACKAGE).includes('admin') ? {'force[with_removed]': 1} : {}
// const changedField = 'changed__time';

const collator = new Intl.Collator();
const sortByName = function (a, b) {
    let cmp = collator.compare(a?.name_ || '', b?.name_ || '')
    if (cmp) {
        return cmp;
    }
    return a.id - b.id;
}

export default {
    state: {
        shipmentsFullLoad: false,
        shipmentsLiteLoad: false,
        editShipment: {},
        shipments: [
            // {
            //     id: 0,
            //     shipmentLegs: [],
            // }
        ],
    },
    actions: {
        fetchShipments/*all*/({dispatch, commit, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchShipments', time: Date.now() / 1000})

                const params = getApiProps('shipments', args)
                this.$api.shipments.getAll({...params, ...with_removed})
                    .then((response) => {
                        if (response.status < 400 && !response.data.error && !response.data.errors) {
                            commit('updateShipments', response.data)
                            setTimeout(() => {
                                commit('setShipmentsLiteLoad', true)
                                commit('setShipmentsFullLoad', true)
                            }, 500)
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchShipments', inprogress: false})
                    });
            })
        },
        fetchShipment/*all*/({dispatch, commit, getters}, id) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchShipment', time: Date.now() / 1000})

                const params = {}//getApiProps('shipments', args)
                this.$api.shipments.find(id,{...params})
                    .then((response) => {
                        if (response.status < 400 && !response.data.error && !response.data.errors) {
                            commit('updateShipment', response?.data)
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchShipment', inprogress: false})
                    });
            })
        },

        fetchShipmentsChanged({dispatch, commit, getters}, args) {
            if (!getters.apiToken) {
                return
            }
            dispatch('setLastCall', {name: 'fetchShipmentsChanged', time: Date.now() / 1000})

            args = {...consts.querySettings.filter, ...args}
            return dispatch('fetchShipments', args)
                .then((data) => {
                    commit('updateShipments', data)
                    return dispatch('fetchShipments', {fields: 'id', expand: ''})
                })
                .then((data) => {
                    commit('filterShipments', data)
                })
                .finally(() => {
                    dispatch('setLastCall', {name: 'fetchShipmentsChanged', inprogress: false})
                });
        },

        saveShipment({dispatch}, shipment) {
            let fn = (shipment.id) ? 'updateShipment' : 'createShipment'
            return dispatch(fn, shipment);
        },
        createShipment({dispatch, commit}, shipment) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('shipments')
                this.$api.shipments.create(shipment, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error && !response.data.errors) {
                            commit('updateShipment', response?.data)
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    })
                    .finally(() => dispatch('fetchShipmentsDevices'));
            })
        },
        updateShipment({dispatch, commit}, shipment) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('shipments')
                this.$api.shipments.update(shipment.id, shipment, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error && !response.data.errors) {
                            commit('updateShipment', response?.data)
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    })
                    .finally(() => dispatch('fetchShipmentsDevices'));
            })
        },
        completeShipment({dispatch}, shipmentId) {//remove
            return new Promise((resolve, reject) => {
                const params = getApiProps('shipments')
                this.$api.shipments.completeShipment(shipmentId, {...params, ...with_removed})//remove
                    .then((response) => {
                        if (response.status < 400 && response?.data && !response?.data?.error && !response?.data?.errors) {
                            dispatch('fetchShipment', response.data.id)
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    })
                    .finally(() => dispatch('fetchShipmentsDevices'));
            })
        },
        deleteShipment({dispatch, commit}, shipmentId) {//remove
            return new Promise((resolve, reject) => {
                const params = getApiProps('shipments')
                this.$api.shipments.delete(shipmentId, {...params, ...with_removed})//remove
                    .then((response) => {
                        if (response.status < 400 && !response?.data?.error && !response?.data?.errors) {
                            if (!response.data.id) commit('deleteShipment', shipmentId)
                            else commit('updateShipment', response?.data?.id)
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    })
                    .finally(() => dispatch('fetchShipmentsDevices'));
            })
        },
    },
    mutations: {
        setShipmentsFullLoad(state, FullLoad) {
            state.shipmentsFullLoad = state.shipmentsFullLoad || FullLoad
        },
        setShipmentsLiteLoad(state, LitaLoad) {
            state.shipmentsLiteLoad = state.shipmentsLiteLoad || LitaLoad
        },
        setEditShipment(state, shipment) {
            state.editShipment = shipment
        },

        setShipments(state, nShipments) {
            nShipments = nShipments.map(u => {
                if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                return u //Object.freeze(u)
            })
            nShipments.sort(sortByName)
            state.shipments = nShipments
        },

        updateShipments(state, nShipments) {
            if (!state.shipments.length) {
                nShipments = nShipments.map(u => {
                    if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                    return u //Object.freeze(u)
                })
                //nShipments.sort(sortByName)
                state.shipments = nShipments
                // const chunks = arraySplitIntoChunks(nShipments)//.reverse();
                // const pushOnRenderTask = () => {
                //     if (chunks.length === 0) return;
                //     let chunk = chunks.pop();
                //     state.shipments.push(...chunk);
                //     setTimeout(() => {
                //     requestAnimationFrame(pushOnRenderTask);
                //     }, 300)
                //     //this.$nextTick().then(() => pushOnRenderTask())
                // }
                // pushOnRenderTask();
                return true
            }

            nShipments.forEach(function (nShipment) {
                if (nShipment?.name) nShipment.name_ = nShipment.name.toLocaleLowerCase()
                let i = state.shipments.findIndex(u => (u.id == nShipment.id))
                if (i < 0) {
                    state.shipments.push(nShipment) //(Object.freeze(nShipment))
                } else {
                    // if (!state.shipmentsFullLoad/* || state.shipments[i][changedField] != nShipment[changedField]*/) {
                    updateObjectByDiff(state.shipments[i], nShipment)
                    // delete nShipment.id
                    // nShipment = {...state.shipments[i], ...nShipment}
                    // state.shipments[i] = nShipment //Object.freeze(nShipment)
                }
            })

        },
        filterShipments(state, nShipments) {
            // let Ids = state.shipments.map(u=> u.id)
            let nIds = nShipments.map(u => u.id)
            let removedIds = state.shipments.filter(u => u.id > 0 && !nIds.includes(u.id)).map(u => u.id)
            removedIds.forEach(removedId => {
                let i = state.shipments.findIndex(u => (u.id == removedId))
                if (i != -1) {
                    state.shipments.splice(i, 1)
                }
            })
        },
        updateShipment(state, nShipment) {
            if (nShipment?.name) nShipment.name_ = nShipment.name.toLocaleLowerCase()
            let i = state.shipments.findIndex(u => (u.id == nShipment.id))
            if (i < 0) {
                state.shipments.push(nShipment) //(Object.freeze(nShipment))
            } else {
                // if (state.shipmentsFullLoad || state.shipments[i][changedField] != nShipment[changedField]) {
                updateObjectByDiff(state.shipments[i], nShipment)
                console.log(state.shipments[i])
                // delete nShipment.id
                // nShipment = {...state.shipments[i], ...nShipment}
                // state.shipments[i] = nShipment //Object.freeze(nShipment)
            }
        },
        deleteShipment(state, shipmentId) {
            let i = state.shipments.findIndex(u => (u.id == shipmentId))
            if (i != -1) {
                state.shipments.splice(i, 1)
            }
        },

        clearShipments(state) {
            state.shipments = []
            state.shipmentsFullLoad = false
        },
    },
    getters: {
        isShipmentsFullLoad(state) {
            return state.shipmentsFullLoad
        },
        isShipmentsLiteLoad(state) {
            return state.shipmentsLiteLoad
        },
        getShipments(state) {
            return state.shipments
        },
        getEditShipment(state) {
            return state.editShipment
        }
    }
}
